header {
  height: 80px;
  background: #fff;
}
header .go-back {
  display: none;
}
header .header-title {
  display: none;
}
header nav {
  white-space: nowrap;
}
header nav > a {
  color: #393939;
  font-size: 18px;
  margin: 0 4px;
  padding: 0 20px;
  line-height: 30px;
  letter-spacing: 1px;
  position: relative;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  border-bottom: 4px solid transparent;
}
header nav > a:last-child {
  margin-right: 0;
}
header nav > a + a::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -6px;
  width: 4px;
  height: 4px;
  margin-top: -2px;
  border-radius: 50%;
  background-color: #000;
}
header nav > a::after {
  position: absolute;
  left: 100%;
  top: 0;
  border-bottom: 4px solid transparent;
  width: 0;
  height: 100%;
  content: "";
  -webkit-transition: all 300ms;
  transition: all 300ms;
}
header nav > a.active {
  color: #c70100;
  border-bottom: 4px solid #c70100;
}
header nav > a:hover {
  color: #c70100;
}
header nav > a:hover::after {
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  border-bottom-color: #c70100;
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
}
header nav > a:hover + a::after {
  left: 0;
}

footer {
  background: #fff;
  margin-top: 70px;
  border-top: 2px solid #DCDADA;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 77px;
  text-align: center;
  font-size: 12px;
  color: #505050;
}
footer p {
  text-decoration: underline;
}
footer p img {
  width: 15px;
}

.breadcrumb {
  margin-top: 30px;
  margin-bottom: 30px;
}
.breadcrumb ul li a {
  color: #656565;
}
.breadcrumb ul li a img {
  margin-top: -2px;
}
.breadcrumb ul li span {
  margin: 0 8px;
}

.title-special {
  display: flex;
  align-items: flex-start;
  color: #c70100;
}
.title-special .vertical-bar {
  width: 3px;
  height: 60px;
  background-color: #c70100;
  margin-right: 30px;
}
.title-special p {
  text-align: justify;
}
.title-special .cn {
  font-size: 22px;
}
.title-special .en {
  font-size: 14px;
}

@media screen and (max-width: 1080px) {
  header {
    height: 55px;
    padding: 0 10px;
  }
  header.taihe-bg-red {
    background: #c70100;
  }
  header.taihe-bg-red .line {
    background: #fff !important;
  }
  header.taihe-bg-red .logo {
    display: none;
  }
  header.taihe-bg-red .go-back {
    display: block;
    height: 55px;
    line-height: 55px;
    padding: 0 15px;
    margin-left: -10px;
  }
  header.taihe-bg-red .go-back img {
    height: 20px;
  }
  header .logo img {
    height: 25px;
  }
  header nav {
    display: none;
  }
  header .header-title {
    display: block;
    font-size: 18px;
    color: #fff;
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    height: 55px;
    line-height: 55px;
  }
  header .btn-menu {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 55px;
    overflow: hidden;
  }
  header .btn-menu .line {
    position: absolute;
    top: 25px;
    left: 10px;
    width: 20px;
    height: 2px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    background: #c70100;
  }
  header .btn-menu .line-01 {
    margin-top: -7px;
  }
  header .btn-menu .line-03 {
    margin-top: 7px;
  }
  header .menu-shadow {
    height: 478px;
    width: 100%;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  header .menu-shadow .menu-header {
    height: 55px;
    text-align: center;
    position: relative;
    line-height: 55px;
  }
  header .menu-shadow .menu-header img {
    height: 25px;
    opacity: 0.4;
  }
  header .menu-shadow .menu-header .close {
    display: block;
    color: #c70100;
    font-size: 30px;
    height: 55px;
    line-height: 55px;
    position: absolute;
    right: 10px;
    top: 0;
  }
  header .menu-shadow ul {
    margin-top: 40px;
  }
  header .menu-shadow ul li {
    text-align: center;
    line-height: 50px;
  }
  header .menu-shadow ul li a {
    font-size: 18px;
    color: #959494;
    display: block;
  }
  header .menu-shadow ul li a.active {
    color: #c70100;
  }
  header .menu-shadow ul li a.active::after {
    content: "";
    display: block;
    margin: auto;
    width: 77px;
    height: 3px;
    background: #c70100;
  }

  footer {
    margin-top: 0;
    border-top: 7px solid #F1F2F6;
    color: #959494;
  }
  footer a {
    color: #959494;
  }

  .title-special {
    margin-bottom: 10px;
  }
  .title-special .vertical-bar {
    width: 3px;
    height: 38px;
    margin-right: 10px;
  }
  .title-special .cn {
    font-size: 16px;
  }
  .title-special .en {
    font-size: 12px;
  }
}
@media screen and (min-width: 1080px) {
  .menu-shadow {
    display: none !important;
  }
}
